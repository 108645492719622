var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ricePOS-image-text4-box px-ricePOS-image-text4-box",style:(`
      padding-top: ${_vm.configs.paddingTop}px;
  padding-right: ${_vm.configs.paddingRight}px;
  padding-bottom: ${_vm.configs.paddingBottom}px;
  padding-left: ${_vm.configs.paddingLeft}px;
  background-color:${_vm.configs.backgroundColor};
`),on:{"click":function($event){return _vm.clickItem('bgImage')}}},[_c('h1',{staticClass:"title",class:`edit_${_vm.Xindex}_title`,domProps:{"innerHTML":_vm._s(_vm.$util.rex.getHtml(_vm.configs[`${_vm.nowL}title`] || _vm.configs.title))}}),_c('h2',{staticClass:"desc",class:`edit_${_vm.Xindex}_desc`,domProps:{"innerHTML":_vm._s(_vm.$util.rex.getHtml(_vm.configs[`${_vm.nowL}desc`] || _vm.configs.desc))}}),_c('div',{staticClass:"circle-box"},[_c('div',{staticClass:"bg_circle",style:(`background:linear-gradient(180deg, ${_vm.configs.startBGC} 0%, rgba(255, 255, 255, 0) 30%, #fff 400%)`)}),_vm._l((_vm.configs.items),function(item,index){return _c('div',{key:index,staticClass:"item",style:(`
      transform: rotateZ(${_vm.getR(index)}deg);
      left: calc(50% - ${_vm.configs.iconFontSize / 2})
      top: ${_vm.configs.iconFontSize / 2};
      `)},[_c('div',{staticClass:"circle_h",style:(`transform: rotateZ(-${_vm.getR(index)}deg);`)},[_c('div',{staticClass:"image-text"},[_c('el-image',{attrs:{"src":item.icon}}),_c('div',{staticClass:"icon-desc",domProps:{"innerHTML":_vm._s(_vm.$util.rex.getHtml(item[`${_vm.nowL}title`] || item.title))}})],1)])])})],2),_c('div',{staticClass:"image-box",class:{image2:_vm.configs.type==2}},[_c('el-image',{attrs:{"src":_vm.configs.image,"alt":_vm.configs.alt}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }