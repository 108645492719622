<!--
 * @Author: lzh
 * @Date: 2022-12-14 16:03:20
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 16:27:29
 * @Description: file content
-->
<template>
  <div
    class="ricePOS-image-text4-box px-ricePOS-image-text4-box"
    :style="`
        padding-top: ${configs.paddingTop}px;
    padding-right: ${configs.paddingRight}px;
    padding-bottom: ${configs.paddingBottom}px;
    padding-left: ${configs.paddingLeft}px;
    background-color:${configs.backgroundColor};
  `"
    @click="clickItem('bgImage')"
  >
    <h1
      class="title"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
      :class="`edit_${Xindex}_title`"
    ></h1>
    <h2
      class="desc"
      v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
      :class="`edit_${Xindex}_desc`"
    ></h2>
    <div class="circle-box">
      <!-- 有色容器 -->
      <div
        class="bg_circle"
        :style="`background:linear-gradient(180deg, ${configs.startBGC} 0%, rgba(255, 255, 255, 0) 30%, #fff 400%)`"
      ></div>
      <div
        class="item"
        v-for="item,index in configs.items"
        :key="index"
        :style="`
        transform: rotateZ(${getR(index)}deg);
        left: calc(50% - ${configs.iconFontSize / 2})
        top: ${configs.iconFontSize / 2};
        `"
      >
        <div
          class="circle_h"
          :style="`transform: rotateZ(-${getR(index)}deg);`"
        >
          <div class="image-text">
            <el-image :src="item.icon"></el-image>
            <div
              class="icon-desc"
              v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 圖片容器 -->
    <div
      class="image-box"
      :class="{image2:configs.type==2}"
    >
      <el-image
        :src="configs.image"
        :alt="configs.alt"
      ></el-image>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  name: "self-ricePOS-image-text4",
  mixins: [langMixin],
  props: {
    Xindex: {
      default () {
        return '';
      },
    },
    configs: {
      default () {
        return {
        }
      }
    }
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  data () {
    return {
      isEdit: false
    }
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
    getR (index) {
      let len = this.configs.items.length;
      // l 310  
      // R 50
      let max = 100;
      let p = max / (len - 1);
      let t = 310 + (index * p);
      if (t > 360) t = Math.abs(t - 360)
      return t;
    }
  }
}
</script>

<style lang="less" scoped>
.ricePOS-image-text4-box {
  // min-width: 65vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 334px 60px !important;
  .title {
    font-size: 30px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }
  .desc {
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-top: 6px;
  }
  .circle-box {
    position: relative;
    height: 320px;
    margin-top: 114px;
    .bg_circle {
      position: absolute;
      box-sizing: border-box;
      width: 65vw;
      height: 65vw;
      z-index: 1;
      top: 0;
      left: 50%;
      margin-left: -32.5vw;
      border-radius: 50%;
      // background: linear-gradient(
      //   180deg,
      //   var(--themeColor) 0%,
      //   rgba(190, 28, 66, 0) 30%,
      //   #fff 400%
      // );
      opacity: 0.3;
    }
    .item {
      width: 78px;
      height: calc(65vw / 2 + 39px);
      position: absolute;
      left: calc(50% - 39px);
      top: -39px;
      transform-origin: bottom;
      z-index: 10;
      .circle_h {
        width: 100%;
        height: 100%;
        height: auto;
        text-align: center;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .image-text {
          width: 78px;
          display: flex;
          flex-direction: column;
          align-items: center;
          /deep/ .el-image {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
          .icon-desc {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            word-break: keep-all;
            font-size: 20px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
          }
        }
      }
    }
  }
  .image-box {
    // height: 500px;
    width: 1032px;
    margin: 2px auto;
    position: relative;
    z-index: 5;
    &.image2 {
      width: 650px;
      transform: translateY(-80px);
    }
  }
}

@media screen and (max-width: 1000px) {
  .px-ricePOS-image-text4-box {
    .title {
      font-size: 16px;
    }
    .desc {
      font-size: 12px;
    }
    .circle-box {
      .item {
        .image-text {
          .el-image {
            width: 34px !important;
            height: 34px !important;
          }
          .icon-desc {
            font-size: 12px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .px-ricePOS-image-text4-box {
    padding: 25px 22px 25px 22px !important;
    .title {
      font-size: 15px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .desc {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-top: 2px;
    }
    .circle-box {
      margin-top: 22px;
      width: 100%;
      height: calc((100vw - 46px) * 0.26);
      .bg_circle {
        position: absolute;
        box-sizing: border-box;
        width: calc(100vw - 46px);
        height: calc(100vw - 46px);
        z-index: 1;
        top: 0;
        left: 0;
        margin-left: 0;
        border-radius: 50%;
        opacity: 0.3;
      }
      .item {
        width: 22px;
        height: calc((100vw - 46px) / 2 + 11px);
        position: absolute;
        left: calc((100vw - 46px) / 2 - 11px);
        top: -11px;
        .circle_h {
          width: 22px;
          .image-text {
            width: 100%;
            height: 100%;
            .icon-desc {
              font-size: 10px !important;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #000000;
            }
          }
        }
      }
    }
    .image-box {
      height: auto;
      width: calc(100vw - 46px);
      margin: 2px auto;
      position: relative;
      z-index: 5;
    }
  }
}
</style>